html {
   margin-top: 0px !important;
}
body { font-family: 'Poppins', sans-serif; font-weight: 400; background-color: $white;     display: flex; color: #000000; flex-direction: column; min-height: 100vh; position: relative;}
p{
	margin: 0;
	font-size: $px16;
	line-height: 28px;
	color: #444444;
}
.container {
    max-width: 1340px;
    width: 100%;
    margin: auto;
    padding: 0 15px;
}
a{
	color: #1487E2;
	text-decoration: none;
	transition: 0.3s all ease-in-out;
}
a:-webkit-any-link:focus-visible {
    outline: none;
}
a:hover{
	color: #454545;
	transition: 0.3s all ease-in-out;
}
h1{
	margin: 0;
	color: #353C80;
	font-size: 40px;
	font-weight: 500;
}
h2{
	margin: 0;
	font-size: 32px;
	font-weight: 400;
}
h3{
	margin: 0;
	font-size: $px30;
}
h4{
	margin: 0;
	font-size: $px23;
	line-height: 33px;
}
h5{
	font-size: $px20;
	line-height: 24px;
}
h6{
	font-size: $px17;
	line-height: 28px;
	font-weight: 700;
    line-height: normal;
}
ul{
	padding: 0;
	margin: 0;
}
li{
	list-style: disc;
}

.header{
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
	.header_top{
		background-color: $light-blue1;
		padding: 15px 0px;
		
	ul{
		li{
			display: inline-block;
			margin-right: 20px;
			a{
				font-size: $px12;
				color: $white;
				font-weight: 600;
				display: flex;
				align-items: center;
			}
			
		&:last-child{
			margin-right: 0;
		}
		}
	}
	}

.header_btm{
	.logo{
		width: 230px;
		position: relative;
		top: -29px;
	
	img{
		width: 100%;
	}
	}

.main_menu{
	width: calc(100% - 230px);
		
	nav{
		ul{
			display: flex;
			
			li{
				padding: 0px 15px;
				position: relative;
				    display: inline-block;
				a{
					font-size: $px20;
					color: #283891;
					font-weight: 700;
					display: block;
					text-transform: uppercase;
					
				&:hover{
					color: #30ABE1;
				}
				}
				
			&:last-child{
				padding-right: 0px;
			}
			}
		}
	}
	}	
}
	
}
.sub-menu {
	min-width: 190px;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: #fff;
    border-radius: 0px;
    padding: 10px 0;
    font-size: 16.5px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: none;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    transform: translateY(22px);
    opacity: 0;
    pointer-events: none;
    transition: 0.3s all;
    z-index: 10;
}
.header .header_btm .main_menu nav ul li:hover > .sub-menu {
    transform: translateY(0px);
    pointer-events: all;
    opacity: 1;
}

.header .header_btm .main_menu nav ul li ul.sub-menu  {
	display: block;
}
.header .header_btm .main_menu nav ul li ul.sub-menu li {
    padding: 5px 20px;
    line-height: 1.2;
	display: block;
}
.header .header_btm .main_menu nav ul li ul.sub-menu li a {
    line-height: 1.2;
    font-size: $px15;
    font-weight: 500;
    color: #283891;
}
.header .header_btm .main_menu nav ul li ul.sub-menu li a:hover{
	color: #30ABE1;
}
.current-menu-item > a {
    color: #30ABE1 !important;
}
.header .header_top ul li a i.fa-clock{
	margin-right: 5px;
}
.header .header_top ul li a i.fa-magnifying-glass{
	font-size: $px26;
	margin-left: 12px;
}
.header .header_top ul li a.language{
	font-size: $px14;
	font-weight: 500;
    padding-left: 30px;
    margin-left: 10px;
    position: relative;
}
.header .header_top ul li a.language:before {
    content: '';
    height: 30px;
    width: 1px;
    background-color: #fff;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
}
main{
	flex: 1;
}

.footer{
    padding: 37px 0 29px;
	
.container{
	padding: 0px 145px;
}
.footer_top{
    padding: 0 0 4px;
	h5{
		font-size: $px20;
		line-height: 24px;
		color: #1f1f1f;
		font-weight: 700;
		padding: 0 0 16px;
	}
	p{
		font-size: $px16;
		line-height: 24px;
		color: #1f1f1f;
		padding: 0 0 15px;
		font-family: 'Lora', serif;
		font-weight: 400;
	}
	a{
		font-size: $px16;
		line-height: 24px;
		color: #0b5289;
		font-family: 'Lora', serif;
		font-weight: 700;
	}
.footer_icon {
	padding: 15px 0 0;
		
	ul{
		display: flex;
	
	li{
		margin-right: 6px;
		display: inline-block;
		a{
			width: 30px;
			height: 30px;
			color: #fff;
			background-color: #0098db;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50px;
			
		&:hover{
			background-color: #0b5289;
		}
		}
	}
	}
}

	.footer-add {
		
		span {
			display: block;
			font-size: $px16;
			line-height: 24px;
			color: #26a0de;
			font-family: 'Lora', serif;
			font-weight: 700;
		}
	}
	.footer-txt{
		a{
			color: #0098db;
			text-transform: uppercase;
			font-size: $px12;
			line-height: 32px;
			letter-spacing: 0.9px;
			
		&:hover{
			color: #0b5289;
		}
		}
	}
}
}
.footer_btm {
    border-top: 1px solid #f4f4f4;
    padding: 12px 0 0;
	
p{
    font-size: $px12;
    line-height: 24px;
    color: #1f1f1f;
    font-family: 'Lora', serif;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.3px;
	
a{
	display: inline-block;
    color: #1f1f1f;
	margin-left: 10px;
}
}
}
.pandamic_box{
	background-color: #F0FCFE;
	padding: 32px 0px;
	
h4{
	color: #283891;
	font-weight: 700;
	margin-bottom: 4px;
}
p{
	font-size: $px17;
	color: #000000;
	
a{
	text-decoration: underline;
	color: #1487E2;
}
}
}

.follow_us {
	padding: 50px 0px;
    background-color: #b7e7fc;
}
.follow_us .titles {
    font-size: $px27;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    color: #283891;
    padding-bottom: 40px;
}
.follow_us .titles span {
    font-weight: 400;
}
.follow_social img{
	width: 100%;
}

ul.resp-tabs-list {
    display: flex;
	border-bottom: 2px solid #707070;
	padding: 0px 15px;
}
.resp-tabs-list li{
	font-size: $px21;
	font-weight: 700;
	color: #283891;
	background-color: #F6F6F6;
	margin-right: 12px;
	border: 0;
	width: 25%;
    text-align: center;
	position: relative;
	display: flex;
    align-items: center;
    justify-content: center;
	padding: 0;
}
.resp-tabs-list li a{
	color: #283891;
	padding: 15px 15px;
	display: block;
	width: 100%;
}
.resp-tabs-list li a:hover{
    color: #30ABE1;
	transition: 0.3s all ease-in-out;	
}
.resp-tabs-list li.resp-tab-active {
	background-color: #ffffff;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
	color: #FF5C11;
	padding: 13px 15px !important;
}
.resp-tabs-list li.active {
	background-color: #ffffff;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
	color: #FF5C11;
}
.resp-tabs-list li.resp-tab-active a, .resp-tabs-list li.active a{
	color: #FF5C11;
}
.resp-tabs-list li:before{
	content:'';
	position: absolute;
	left: 0;
	bottom: -3px;
	width: 100%;
	height: 7px;
	background-color: #283891;
	opacity: 0;
	border-radius: 8px;
}
.resp-tabs-list li.resp-tab-active:before, .resp-tabs-list li.active:before{
	opacity: 1;
}
.resp-tabs-list li:last-child{
	margin-right: 0px;
}
.resp-tab-content {
    border: 0;
	padding: 42px 0px 15px;
}
.tab_main{
	padding: 42px 0px 15px;
}
.tab_main div > .tab_main{
	padding: 0 ;
}
.tab_main p{
	font-size: $px20;
	line-height: 30px;
	color: #050505;
a{
	color: #1F8DE3;
	text-decoration: underline;
}
}
.tab_top{
	padding: 0px 53px;
	margin-bottom: 52px;
}

.tabcenter_txt{
	margin-bottom: 36px;
}
.tab_main .tabcenter_txt p{
	font-size: $px17;
	line-height: 28px;
	color: #000000;
	margin-bottom: 10px;
	text-align: left;
}
.tab_main .tabcenter_txt p:first-child{
	margin-bottom: 36px;
	text-align: center;
}
.tab_main .tabcenter_txt h6{
	color: #283891;
	font-weight: 700;
	margin-bottom: 10px;
}

.main_tab{
	background: url('../images/bg1.png') #ffffff no-repeat;
	padding: 85px 0px;
}
.main_tab .container{
	max-width: 1530px;
}
.main_title{
	margin-bottom: 56px;
}
.main_title h1{
	font-size: $px43;
}
.tab_sec{
	background-color: #ffffff;
	padding: 77px 68px 0px;
}

.tab_main .table thead, .table thead{
	background-color: #283891;
}
.table thead tr th{
	font-size: $px16;
	line-height: 26px;
    font-weight: 600;
    color: #ffffff;
	padding: 19px 34px;
	border-right: 1px solid #ffffff;
	vertical-align: top;
}
.table thead tr th:last-child{
	border-right: 0
}
.table tr td{
	padding: 19px 34px;
	font-size: 16px;
	line-height: 26px;
	color: #000000;
	border-right: 1px solid rgba(112, 112, 112, 0.2);
	border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}
.table tr td:last-child{
	border-right: 0
}
.tab_main .table-responsive, .tabing_main .table-responsive  {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll; 
    height: 897px;
}
.tab_main .table-responsive::-webkit-scrollbar, .tabing_main .table-responsive::-webkit-scrollbar  {
  display: none; /* for Chrome, Safari, and Opera */
}

.hearing_tabtop{	
	margin-bottom: 45px;
	display: flex;
	flex-wrap: wrap;
}
.system_tab .hearing_tabtop{	
	padding: 0px 53px;
}
.tab_main div > .tab_main.hearing_tab{
	padding: 0px 53px 0;
}
.hearing_tab h6{
	color: #283891;
	font-weight: 700;
	margin-bottom: 10px;
}
.system_tab{
	padding: 51px 0px 15px;
}
.hearing_tab p, .system_tab p{
	font-size: $px17;
	line-height: 28px;
	color: #000000;
	margin-bottom: 22px;
}
.hearing_tableft{
	width: 567px;
}
.hearing_tabright{
	width: calc(100% - 567px);
	padding-left: 62px;
}
.hearing_tabtop img{
	width: 100%;
}
.hearing_tabtxt ul.list-check{
	padding: 0;
	margin-bottom: 33px;
}
.hearing_tabtxt ul.list-check li{
	display: block;
	font-size: $px17;
	line-height: 28px;
	color: #000000;
	margin-bottom: 10px;
	position: relative;
	padding-left: 22px;
}
.hearing_tabtxt ul.list-check li:before{
	content:'';
	position: absolute;
	left: 0;
	top: -4px;
	width: 17px;
	height: 31px;
	background: url('../images/check.svg') no-repeat;
	background-size: cover;
}
.hearing_tabtxt ul li a{ 
	color: #1487E2;
	text-decoration: underline;
}
.hearing_tabtxt ul li span{ 
	font-weight: 500;
}
.hearing_tabtxt ul li span a{ 
	text-decoration: underline;
	color: #000000;
}
ul.hearing_list {
	padding-left: 20px;
}
ul.hearing_list li{
	font-size: $px17;
	line-height: 28px;
	color: #000000;
	margin-bottom: 10px;
}
ul.hearing_list li a{ 
	color: #1487E2;
	text-decoration: underline;
}
ul.hearing_list li:marker {
	color: #1487E2;
	font-size: 12px;
}
.system_box{
	padding: 40px 20px;
	background-color: #EFF8FC;
	position: relative;
}
.system_box:before{
	content:'';
	position: absolute;
	width: 68px;
	height: 100%;
	background: #EFF8FC;
	top: 0;
	left: -68px;
}
.system_box:after{
	content:'';
	position: absolute;
	width: 68px;
	height: 100%;
	background: #EFF8FC;
	top: 0;
	right: -68px;
}

.system_box h3{
	font-size: 30px;
	font-weight: 500;
	color: #353C80;
	margin-bottom: 8px;
}
.system_box p{
	font-size: $px17;
	line-height: 28px;
	color: #000000;
	margin-bottom: 0;
	
a{
	color: #1487E2;
}
}

.video_txt{
	padding: 55px 53px;
}
.video_leftxt{
	width: 50%;
	padding-right: 25px;
}
.video_leftxt p{
	font-size: $px17;
	line-height: 28px;
	color: #000000;
	margin-bottom: 10px;
	
a{
	color: #1487E2;
}
}
.video_leftxt p span{
	display: block;
	margin-bottom: 25px;
	font-size: 20px;
	line-height: 31px;
}

.video_rigtxt{
	width: 50%;
	padding-left: 63px;
	
iframe{
	width: 100%;
	height: 100%;
	min-height: 300px;
}
}

.video_mainbox {
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
	padding: 27px 44px;
	margin-bottom: 42px;
}
.video_mainbox p{
	font-size: 20px;
	line-height: 31px;
	color: #000000;
	margin-bottom: 22px;
	text-align: center;
}
.video_box{

h5{
	font-size: 23px;
	font-weight: 500;
	color: #353C80;
	text-transform: uppercase;
	text-align: center;
	display: block;
	margin: 0;
}
}
.video_box iframe{
	width: 100%;
	height: 320px;
	margin-bottom: 10px;
}
.video_boxleft{
	width: 50%;
	margin-right: 40px;
}
.video_boxright{
	width: 50%
}
.video_txt h6{
	color: #1487E2;
	font-weight: 700;
	margin-bottom: 10px;
}
.video_box.fullbox .video_boxleft{
	padding-right: 0;
}

.tab_main div > .tab_main.click_tab{
	padding: 0px 55px 0px;
}
.click_tab p{
	font-size: $px17;
	line-height: 28px;
	color: #000000;
	margin-bottom: 24px;
}
.click_tabmain{
	display: flex;
	flex-wrap: wrap;
}
.click_tableft{
	width: 441px;
	
ul{
	padding: 0;
	margin: 0;
	
li{
	display: block;
	margin-bottom: 22px;
	
a.btn.btn-primary{
	display: block;
}
}
}
}
.click_tabright{
	width: calc(100% - 441px);
	padding-left: 122px;
}

.btn.btn-primary{	
	background-color: #1487E2;
	border-radius: 50px;
	font-size: $px18;
	line-height: 28px;
	color: #ffffff;
	font-weight: 600;
	display: inline-block;
	padding: 10px 37px;
	
&:hover{
	background-color: #FF5C11;
}
}
.click_tab img{
	width: 100%;
}


.aligncenter,.alignleft,.alignright {
    display: block;
    padding: 0;
}

.aligncenter {
    float: none;
    margin: .5em auto 1em;
}

.alignright {
    float: right;
    margin: .5em 0 1em 1em;
}

.alignleft {
    float: left;
    margin: .5em 1em 1em 0;
}

.wp-caption {
    padding: 5px 0;
    border: 1px solid #555;
    background: #444;
    text-align: center;
}

.wp-caption img {
    display: inline;
}

.wp-caption p.wp-caption-text {
    margin: 5px 0 0;
    padding: 0;
    text-align: center;
    font-size: 75%;
    font-weight: 100;
    font-style: italic;
    color: #ddd;
}
.dataTables_length, .dataTables_filter{
	display: none;
}
.table thead th:before {
	display: none !important;
}
.table thead th:after {
    content: "" !important;
    position: absolute !important;
    right: 14px !important;
    top: 28px !important;
    width: 12px;
    height: 9px;
    background: url(../images/arrow.svg) no-repeat;
    opacity: 1 !important;
}

.search-form {
	background: #E8E8E4;
    position: absolute;
    top: 41px;
    right: 8%;
    width: 279px;
    height: 44px;
    line-height: 21px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-top: 1px solid #1F8DE3;
    display: none;
    z-index: 999;
	padding: 5px;
}
.search-form:before {
    content: "";
    position: absolute;
    top: -22px;
    right: 17px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
}
.search-form input[type="search"] {
    width: 190px;
    padding: 5px 10px;
    margin-left: 0;
    border: 1px solid #1F8DE3;
    outline: none;
}
.search-form input[type="submit"] {
	width: 77px;
    padding: 3px 0;
    background: #1F8DE3;
    color: #fff;
    border: 1px solid #1F8DE3;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    position: absolute;
    right: 3px;
    margin: 0;
    top: 3px;
    font-size: 14px;
}
.header_top .container{
	position: relative;
}

.nav-icon { 
	display: none; 
	-webkit-transform: rotate(0deg); 
	-moz-transform: rotate(0deg);
	 -o-transform: rotate(0deg); 
	 transform: rotate(0deg); 
	 -webkit-transition: .5s ease-in-out; 
	-moz-transition: .5s ease-in-out; 
	-o-transition: .5s ease-in-out; 
	transition: .5s ease-in-out; 
	cursor: pointer; 
	z-index: 9; 
	width: 35px; 
	height: 24px; 
	position: absolute; 
	top: 90px; 
	right: 15px;  
	border-radius: 0; 
}
.nav-icon span{
	display: block; 
	position: absolute; 
	height: 3px; 
	background: #353C80; 
	opacity: 1; 
	left: 6px; 
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg); 
	-o-transform: rotate(0deg); 
	transform: rotate(0deg);  
	-webkit-transition: .25s ease-in-out; 
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	 transition: .25s ease-in-out; 
	 border-radius: 20px; 
 }
.nav-icon span:nth-child(1) { 
	top: 0px; 
	width: 35px; 
	left: auto; 
}
.nav-icon span:nth-child(2)	{ 
	top: 10px; 
	width: 35px;  
	left: auto;  
}
.nav-icon span:nth-child(3)	{ 
	top: 20px; 
	width: 35px;  
	left: auto;  
}
.nav-icon.open span:nth-child(1){ 
	top: 10px; 
	right: 0px; 
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg); 
	-o-transform: rotate(135deg); 
	transform: rotate(135deg);
}
.nav-icon.open span:nth-child(2){ 
	opacity: 0; 
}
.nav-icon.open span:nth-child(3){ 
	top: 10px;
	-webkit-transform: rotate(-135deg); 
	-moz-transform: rotate(-135deg); 
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg); 
	width: 35px;  
	right: 0px; 
}
.bg_sec{
	padding: 75px 68px 0px; 
	background-color: #FFFFFF;
}
.title {
	margin-bottom: 49px;
}
.contact_txt{
	padding-left: 70px;
	margin-bottom: 49px;
	
	h6{
		color: #000000;
		font-size: 20px;
		font-weight: 600;
	}
	p{
		color: #000000;
		font-size: 20px;
		line-height: 31px;
		
	a{
		color: #000000;
		text-decoration: none;
		display: block;
	}
	}
}
.contact_txt p a.mail_link{
	color: #2680EB;
	font-size: 20px;
	line-height: 31px;
	text-decoration: underline;
}
.contact_form{
	padding-left: 47px;
	max-width: 604px;
}
.contact_form .form-group{
	margin-bottom: 8px;
}
.contact_form input, input, input.form-control{
    padding: 12px 18px;
    background-color: #ffffff;
	font-size: 13px;
	color: #707070;
	font-weight: 300;
	border: 1px solid #E4E4E4;
	width: 100%;
	border-radius: 0;
}
.contact_form textarea, textarea, textarea.form-control{
    padding: 12px 18px;
    background-color: #ffffff;
	font-size: 13px;
	color: #707070;
	font-weight: 300;
	border: 1px solid #E4E4E4;
	height: 206px;
	font-family: 'Poppins', sans-serif;
	width: 100%;
	border-radius: 0;
}
.contact_form select{
    padding: 12px 18px;
    background-color: #ffffff;
	font-size: 13px;
	color: #707070;
	font-weight: 300;
	border: 1px solid #E4E4E4;
	width: 100%;
}
.contact_form input[type=submit], input[type=submit]{
	background: #1487E2;
    color: #ffffff;
    border-radius: 50px;
    padding: 10px 38px;
    border: 0;
    font-size: 18px;
    line-height: 28px;
	font-weight: 600;
    -webkit-appearance: none;
    display: block;
	text-align: center;
	margin-top: 27px;
}
.contact_form input[type=submit]{
	display: inline-block;
	width: auto;
}
.contact_form input[type=submit]:hover, input[type=submit]:hover{
    background: #FF5C11;
	transition: 0.3s all ease-in-out;
}
.contact_map iframe{
	height: 649px;
}
.select-selected:after {
    position: absolute;
    content: "";
    top: 42px;
    right: 20px;
    width: 10px;
    height: 8px;
    background: url(../images/select-arrow.png) no-repeat;
}
.tt-select { position: relative; height: auto; padding: 0px; text-align: left; }
.tt-select select { display: none; }
.select-selected { background-color: #fff; color: #a8afb7; }
.select-selected:before { position: absolute; content: ""; top: 0; right: 1px;  width: 40px;  height: 45px; background: #E4E4E4; }
.select-selected:after { position: absolute; content: ""; top: 22px; right: 14px; width: 10px; height: 8px; background: url('../images/select-arrow.png') no-repeat; }
.select-selected { background-color: #ffffff;  border: 1px solid #E4E4E4; font-size: 13px; color: #707070; font-weight: 300; padding: 12px 32px 12px 18px; width: 100%;
 cursor: pointer; }
.select-items div { font-size: 13px; color: #707070; font-weight: 300; padding: 0px 19px 6px; }
.select-items div:last-child { padding-bottom: 0; }
.select-items { position: absolute;  background-color: #ffffff;  top: 100%; left: 0;  right: 0; z-index: 9;  padding: 9px 0px; border-radius: 21px;
filter: drop-shadow(0 0 3px rgba(67,112,221,0.29)); }
.select-hide { display: none; }
.select-items div:hover, .same-as-selected { background-color: #f3f6fb; }

.form-control:focus {
    color: #707070;
    background-color: #ffffff;
    border-color: #E4E4E4;
    outline: 0;
    box-shadow: none;
}
input:focus-visible {
    outline: none;
}
.whatwe_sec{
	padding-bottom: 0;
}
.carrer_sec{
	padding: 64px 68px 68px;
}
.carrer_txt{
	padding-left: 44px;
	
	h1{
		margin-bottom: 13px;
	}
	span{
		display: block;
		margin-bottom: 17px;
		font-size: 20px;
		line-height: 31px;
		color: #000000;
	}
	p{
		font-size: 17px;
		line-height: 28px;
		color: #000000;
	
	a{
		color: #1487E2;
		padding-left: 10px;
		display: inline-block;
		text-decoration: underline;
		
	&:hover{
		color: #353C80;
	}
	}
	}
}
.carrer_img {
	position: relative;
	margin-left: 51px;
	
	img{
		width: 100%;
	}
	a{
		background-color: #1487E2;
		display: block;
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
		text-align: center;
		padding: 8px 10px;
		color: #ffffff;
		position: absolute;
		width: 100%;
		bottom: 0;
		
	&:hover{
		background-color: #FF5C11;
	}
	}
}
.carrer_contact{
	background-color: #F0FCFE;
	padding: 34px 0px;
}
.carrer_contact .contact_form{
	max-width: 1364px;
	margin: 0 auto;
	padding: 0;
	h2{
		color: #283891;
		margin-bottom: 37px;
		text-align: center;
	}
}

.carrer_contact .contact_form input , .carrer_contact .select-selected, .carrer_contact .contact_form textarea{
    text-align: center;
}
.carrer_contact form{
	display: flex;
    flex-wrap: wrap;
	justify-content: center;
}
.carrer_contact .form-group.w-30, .carrer_contact .form-group:nth-child(2), .carrer_contact .form-group:nth-child(3), .carrer_contact .form-group:nth-child(4){
	width: 33.06%;
	margin-right: 5px;
}
.carrer_contact .form-group.w-30:nth-child(3), .carrer_contact .form-group:nth-child(4){
	margin-right: 0px;
}
.carrer_contact .form-group:nth-child(5){
	width: 100%;
}
.carrer_contact .contact_form input[type=submit]{	
	margin-top: 15px;
}
.gtranslate_wrapper select{    
	background: transparent !important;
    border: 0 !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    padding-left: 10px !important;
    margin-left: 10px !important;
    position: relative !important;
    color: #ffffff !important;
    width: 95px !important;
}
.gtranslate_wrapper option{    
    color: #283891 !important;
}
select:focus-visible {
    outline: none;
}
.site-main article.page {
	max-width: 1500px;
    margin: 0px auto;
    padding: 30px 95px;
    background-color: #FFFFFF;
}
.page-template-default h1 {
    margin-bottom: 13px;
}
.page-template-default p {
    font-size: 17px;
    line-height: 28px;
    color: #000000;
}
.event_mainbox{
	padding: 81px 86px 0px;
	background-color: #FFFFFF;
}
.event_box{
	padding: 16px 20px 18px;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
	position: relative;
	margin-bottom: 45px;
	display: none;
}
.event-col{
	padding: 0 21px;
}
.event_img{
	margin-bottom: 29px;
	display: block;
    position: relative;
    padding-bottom: 60.87%;
}
.event_img img{
	margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.event_txt{
	padding: 0px 20px;
	
h5{
	font-weight: bold;
	color: #353C80;
	margin-bottom: 13px;
	padding-right: 20px;
}
p{
	font-size: 16px;
	line-height: 28px;
	min-height: 160px;
	
a{
	display: block;
	color: #30ABE1;
}
}
a.event-arrow{
	border: 1px solid #A7646B;
	width: 51px;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -20px;
	
img{
	width: 14px; 
}
}
}

.load_btn{
	width: 100%;
	text-align: center; 
}
.press_event{
    padding: 35px 86px 0px;
    background-color: #FFFFFF;
}
.press_event .event_mainbox {
    padding: 0px;
    background-color: transparent;
}

.press_title{
	margin-bottom: 22px;
}
.press_title h6{
	color: #000000;
	font-weight: 400;
}
.event_calender{
	min-height: 900px;
}

.mission_sec h5{
	color: #283891;
	font-size: 21px;
	font-weight: bold;
	margin-bottom: 15px;
}
.mission_sec p{
	color: #000000;
}
.mission_title h1{
	margin-bottom: 12px;
}
.mission_sec .mission_title p{
	color: #000000;
	font-size: 20px;
	line-height: 31px;
}

.mission_txt p span{
	color: #000000;
	font-size: 20px;
	line-height: 31px;
	margin-bottom: 25px;
    display: block;
}
.mission_txt{
	padding-left: 44px;
	margin-bottom: 13px;
}
.mission_img{
    margin-left: 38px;
	margin-bottom: 13px;

img{
	width: 100%;
}
}
.mission_sec .col-7, .adult_box .col-7 {
	width: 50.87%;
}
.mission_sec .col-5, .adult_box .col-5{
	width: 49.1%;
}
.history_txt{
	padding-left: 44px;
	margin-bottom: 54px;
	max-width: 1196px;
}
.history_txt h5{
    margin-top: 20px;
}
.history_txt p{
	margin-bottom: 10px;
}
.history_txt.value_txt{
	margin-bottom: 26px;
}
.client_sec{
	background-color: #ffffff;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
	margin-left: 44px;
	max-width: 1152px;
}
.client-slider{
	padding: 34px 38px 75px;
}
.client-slider h5{
	margin-bottom: 12px;
	color: #FF5C11;
}
.client-slider p {
    margin-bottom: 10px;
}
.client-slider .slick-dots {
    bottom: 32px;
	left: 0;
}
.client-slider .slick-dots li button {
    width: 15px;
    height: 15px;
	padding: 0;
}
.client-slider .slick-dots li button:before {
    width: 15px;
    height: 15px;
    font-size: 0;
    line-height: 18px;
    background-color: #E8E8E8;
    opacity: 1;
    border-radius: 50px;
}
.client-slider .slick-dots li.slick-active button:before {
    opacity: 1;
	background-color: #283891;
}

.social_service{
	padding: 56px 0;
}
.social_service .title {
    margin-bottom: 88px;
}
.socialvideo_box{
	max-width: 1008px;
	margin: 0 auto;
	position: relative;
	margin-top: -55px;
	margin-bottom: 58px;
}
.socialvideo_box > img {
    width: 100%;
}
.socialvideo_box a.video_btn {
    position: absolute;
    width: 118px;
    height: 118px;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
}
.social_sec{
	padding: 0px 86px;
}
.social_box{
    padding-left: 26px;
    max-width: 1240px;
	
h5{
	color: #283891;
	font-weight: bold;
	margin-bottom: 10px;
}
span{
	color: #000000;
	font-size: 20px;
	line-height: 31px;
	display: block;
	margin-bottom: 20px;
}
p{
	margin-bottom: 10px;
}
}
.social_box h6{
	color: #283891;
	font-weight: bold;
	margin-bottom: 10px;
	margin-top: 60px;
}
.social_txt{
	margin-bottom: 60px;
}

.social_list ul {
    padding: 0;
    margin-bottom: 33px;
	-moz-columns: 4;
    columns: 4;
	
li{
	display: block;
    font-size: 1.0625rem;
    line-height: 28px;
    color: #000000;
    margin-bottom: 10px;
    position: relative;
    padding-left: 22px;
	
&:before{
	content: "";
    position: absolute;
    left: 0;
    top: -4px;
    width: 17px;
    height: 31px;
    background: url(../images/check.svg) no-repeat;
    background-size: cover;

}
}
}

.connect_box {
    background-color: #F0FCFE;
    padding: 41px 0px;
	
h4{
	color: #283891;
	margin-bottom: 5px;
	font-weight: 700;
}
p{
	font-size: 17px;
	color: #000000;
	margin-bottom: 15px;
}
ul{
	padding: 0;
	
	
li{
	font-style: italic;
	font-size: 17px;
	display: block;
	color: #000000;
	margin-right: 10px;
	padding-right: 10px;
	position: relative;
	
a{
	text-decoration: underline;
	font-style: normal;
}

&:last-child{
	padding-right: 0;
	margin-right: 0;
}
}

}	
}
.connect_box ul li:before{
	content: '';
	position: absolute;
	right: 0;
	top: 5px;
    width: 2px;
    height: 16px;
	background-color: #AAA3A3;
}
.connect_box ul li:last-child:before{
	display: none;
}
.connect_box .container{
	max-width: 1500px;
}

.expungement_main{
	padding-left: 26px;
	
h1{
	margin-bottom: 10px;
}
span{
	color: #000000;
	font-size: 20px;
	line-height: 31px;
	display: block;
	margin-bottom: 30px;
	font-style: normal;
	font-weight: 400;
}
h6{
	color: #283891;
	margin-bottom: 15px;
	position: relative;
	max-width: 595px;
}
p{
	font-size: 15px;
	line-height: 25px;
	color: #252525;
	font-style: italic;
	font-weight: 500;
	max-width: 596px;
}

ul{
	margin-bottom: 10px;
	padding-left: 15px;
	max-width: 555px;
	li{
		font-size: 17px;
		line-height: 28px;
		color: #252525;
		margin-bottom: 15px;
		list-style: decimal;
		padding-left: 3px;
		
	em{
		font-style: normal;
		font-weight: 600;
	}
		
	a{
		text-decoration: underline;
	}
	}
}
}
.expungement_box{
	margin-bottom: 16px;
}
.expungement_main ul li::marker {
	font-weight: 700;
	color: #283891;
}
.expungement_img img{
	width: 100%;
	padding-left: 38px;
}
.expungement_box:nth-child(2) .expungement_txt h6{
	padding-top: 28px;
}
.expungement_box:nth-child(2) .expungement_txt h6:before{
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 1px;
	background: url('../images/line.png') no-repeat;
	background-size: 100%;
}
.expungement_sec .carrer_sec{
	padding-bottom: 0;
}
.frequent_tab .top_row {
	margin: 0;
	padding: 25px 5px 13px;
	background-color: #F8F8F8;
}
.frequent_tab ul li{
	display: block;
	width: 33.3%;
	padding: 25px 15px 15px 22px;
}

.frequent_tab ul li:nth-child(n+1):nth-child(-n+3), .frequent_tab ul li:nth-child(n+7):nth-child(-n+9), .frequent_tab ul li:nth-child(n+13):nth-child(-n+15)
, .frequent_tab ul li:nth-child(n+19):nth-child(-n+21) {
    background-color: #F8F8F8
}
.team_box{
	display: flex;
	align-items: center;
}
.frequent_tab .team_img {
    width: 73px;
    height: 73px;
    padding: 0;
}
.frequent_tab .team_img a {
	border: 1px solid #707070;
	background-color: #ffffff;
	border-radius: 50%;
	display: block;
	width: 100%;
    height: 100%;
}
.frequent_tab .team_img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.frequent_tab .team_txt {
    padding-left: 12px;
    max-width: calc(100% - 73px);
    width: calc(100% - 73px);
	
h6{
	font-weight: 600;
	color: #000000;
	margin: 0;
}
p{
	font-size: 17px;
	font-weight: 400;
	line-height: 28px;
	color: #000000;
	
a{
	color: #000000;
	
&:hover{
	color: #1587E2;
	text-decoration: underline;
}
}
}
}

.meet_tab .resp-tab-content {
	padding: 28px 0 0;
}

.ourpeople_tab {
	padding: 36px 68px 0px;
}
.ourpeople_txt{
	max-width: 1137px;
	margin: 0 auto;
	margin-bottom: 41px;
	text-align: center;
}
.ourpeople_tab .resp-tabs-list li{
	width: 204px;
}
.load_btn a.btn.btn-primary:hover{
    background-color: #FF5C11;
}

.accordion-item{
	border: 0;
	border-radius: 0;
	margin-bottom: 26px;
}
.accordion-button{
    border-radius: 0 !important;
	background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
	color: #353C80;
	font-size: 21px;
	font-weight: bold;
	padding: 15px 70px 15px 45px;
}
.accordion-button:hover{
	background-color: #1F8DE3;
	color: #ffffff;
	box-shadow: none;
	transition: 0.3s all ease-in-out;
}
.accordion-item.highlight {
	background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}
.accordion-item.highlight .accordion-button{
	background-color: #ffffff;
	color: #FF5C11;
	box-shadow: none;
}
.accordion-button:focus{
	background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    color: #353C80;
}
.accordion-button:focus-visible {
    outline: none;
}
.adult_sec {
	padding-bottom: 40px;
}
.adult_box{
	h1{
		line-height: 51px;
		max-width: 535px;
		margin-bottom: 10px;
	}
	span{
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 31px;
		display: block;
	}
}
.adult_sec .mission_img {
    margin-bottom: 55px;
}
.accordion-body{
	padding: 10px 45px 19px 45px;
	background-color: #ffffff;
}
.accordion-body p{
	font-size: 18px;
	color: #000000;
	margin-bottom: 15px;
	
span{
	font-size: 18px;
	font-weight: 600;
	display: inline-block;
	    margin: 0;
    line-height: 28px;
}
}
.accordion-body ul{
	margin-bottom: 19px;
	padding-left: 58px;
	
&:last-child{
	margin-bottom: 0;
}
}
.accordion-body ul li{
	font-size: 18px;
	line-height: 28px;
	color: #000000;
	display: block;
	position: relative;
	margin-bottom: 6px;
	
&:last-child{
	margin-bottom: 0;
}
	
a{
	color: #1487E2;
	text-decoration: underline;
}
}
.accordion-body ul li:before {
	content: '';
	position: absolute;
	left: -20px;
	top: 11px;
	width: 5px;
	height: 5px;
	background-color: #1487E2;
	border-radius: 50%;
}
.accordion-body h6{
	color: #353C80;
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 9px;
}
.accordion-button::after{
	background: url('../images/down-arrow.svg') no-repeat !important;
	width: 51px !important;
    height: 46px !important;
	position: absolute;
    right: 12px;
}
.accordion-button:not(.collapsed)::after {
   background: url('../images/down-arrow.svg') no-repeat !important;
}
.accordion-body .btn.btn-primary {
    margin-top: 15px;
}
.accountability_sec {
    padding-bottom: 84px;
}
.accountability_sec .accordion-body{
	padding: 10px 45px 19px 45px;
}
.accountability_sec .accordion-body .accordion-main{
	padding-bottom: 20px;
	padding-right: 45px;
}
.accordion-item.highlight #sub-accordionExample .accordion-button, .accordion-item.highlight #sub-accordionExample1 .accordion-button, 
.accordion-item.highlight #sub-accordionExample2 .accordion-button {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    color: #353C80;
}
.accordion-item.highlight #sub-accordionExample .accordion-item.highlight .accordion-button, .accordion-item.highlight #sub-accordionExample1 .accordion-item.highlight .accordion-button,
.accordion-item.highlight #sub-accordionExample2 .accordion-item.highlight .accordion-button{
    box-shadow: none;
}
.accordion-collapse.show{
	background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    color: #353C80;
}
.accordion-button:not(.collapsed){
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    color: #FF5C11;
}
.accordion-item.highlight #sub-accordionExample .accordion-button:not(.collapsed)::after, .accordion-item.highlight #sub-accordionExample1 .accordion-button:not(.collapsed)::after,
.accordion-item.highlight #sub-accordionExample2 .accordion-button:not(.collapsed)::after {
    transform: inherit;
}
.accordion-item.highlight #sub-accordionExample .acc-sub-item.highlight1 .accordion-button:not(.collapsed)::after,
.accordion-item.highlight #sub-accordionExample1 .acc-sub-item.highlight1 .accordion-button:not(.collapsed)::after,
.accordion-item.highlight #sub-accordionExample2 .acc-sub-item.highlight1 .accordion-button:not(.collapsed)::after {
	transform: var(--bs-accordion-btn-icon-transform);
}
.acc-sub-item{
	margin-bottom: 14px;
}
.highlight1 .collapse:not(.show) {
    display: block; 
}
.accordion-item.highlight #sub-accordionExample .acc-sub-item.highlight1 .accordion-button, .accordion-item.highlight #sub-accordionExample1 .acc-sub-item.highlight1 .accordion-button,
.accordion-item.highlight #sub-accordionExample2 .acc-sub-item.highlight1 .accordion-button {
    background-color: #ffffff;
    color: #FF5C11;
    box-shadow: none;
}
.accordion-item.highlight #sub-accordionExample .acc-sub-item.highlight1, .accordion-item.highlight #sub-accordionExample1 .acc-sub-item.highlight1,
.accordion-item.highlight #sub-accordionExample2 .acc-sub-item.highlight1{
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}
.accountability_sec .accordion-body p {
    font-size: 17px;
}
.accordion-main .hearing_tabtxt{
	padding-left: 25px;
	padding-bottom: 30px;
}
.accordion-body h5{
	font-weight: 600;
	color: #30ABE1;
	font-size: 17px;
	margin-bottom: 7px;
}
.accordion-main .hearing_tabtxt ul {
	padding-left: 0;
}
.accordion-main .hearing_tabtxt ul li {
	font-size: 17px;
	    margin-bottom: 10px;
}
.accordion-main .hearing_tabtxt ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    width: 17px;
    height: 31px;
    background: url(../images/check.svg) no-repeat;
    background-size: cover;
}
.connect_accountbox ul{
	margin-bottom: 42px;
}
.connect_accountbox ul li{
	font-style: normal;
}

.account_imgbox{
	margin-right: 68px;
	text-align: center;
	width: 47.68%;
	
img{
	margin-bottom: 18px;
	width: 100%;
	height: 270px;
	object-fit: cover;
}
p{
	
a{
	color: #50A7EA;
	font-weight: 600;
}
}
	
&:last-child{
	margin-right: 0px;
}
}

.request_formsec{
	padding: 31px 20px 24px;
	border: 1px solid #A2A1A1;
}
.request_formtitle{
	max-width: 755px;
	margin: 0 auto;
	margin-bottom: 34px;
}
.request_formtitle h2{
	font-size: 32px;
	color: #283891;
	margin-bottom: 13px;
}
.request_formtitle p{
	max-width: 525px;
	margin: 0 auto;
}
.form-group{
  position: relative;
}
.form-group label{
	position: absolute;
    top: 9px;
    left: 0;
	right: 0;
	margin: 0 auto;
    transition: all 0.25s ease-in-out;
	font-size: 13px;
	font-weight: 300;
	color: #707070;
	text-align: center;
	padding: 0px 3px;
}
.form-group label span{
	color: #C6C3C3;
	font-size: 11px;	
	margin: 0;
	line-height: normal;
}
.form-group p.has-value label, .form-group:focus p.has-value label {
    opacity: 0;
}
.form-group.focused label{
	display: none;
}

.request_formsec form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
	max-width: 1268px;
	margin: 0 auto;
}
.request_formsec .form-group{
	margin-bottom: 8px;
}
.request_formsec .form-group.w-30,.request_formsec .form-group:nth-child(1), .request_formsec .form-group:nth-child(2), .request_formsec .form-group:nth-child(3), 
.request_formsec .form-group:nth-child(4), .request_formsec .form-group:nth-child(5), .request_formsec .form-group:nth-child(6)  {
    width: 33.06%;
    margin-right: 5px;
}
.request_formsec .form-group:nth-child(4) {
    margin-right: 0px;
}
.request_formsec .form-group:nth-child(7){
	width: 13.06%;
	margin-right: 5px;
}
.request_formsec .form-group:nth-child(8){
	width: 97px;
	margin-right: 5px;
}
.request_formsec .form-group:nth-child(9){
	width: 11.59%;
	margin-right: 0px;
}
.request_formsec .form-group:nth-child(10), .request_formsec .form-group:nth-child(11){
	width: 49.8%;
	margin-right: 5px;
}
.request_formsec .form-group:nth-child(11){
	margin-right: 0px;
}
.request_formsec textarea{
	height: 95px;
}
.juvenlie_box {
	padding: 42px 0px;
}
.juvenlie_box h4{
	margin-bottom: 12px;
}

.termination_sec .mission_img {
    margin-bottom: 50px;
}
.termination_sec .mission_title p{
	margin-bottom: 11px;
}
.termination_sec .mission_title p strong{
	font-weight: 600;
}
.termination_sec .mission_title ul li {
    display: block;
    font-size: 1.0625rem;
    line-height: 28px;
    color: #252525;
    margin-bottom: 10px;
    position: relative;
    padding-left: 22px;
}
.termination_sec .mission_title ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    width: 17px;
    height: 31px;
    background: url(../images/check.svg) no-repeat;
    background-size: cover;
}
.probation_txt{
	margin-bottom: 75px;
	padding-left: 44px;
}
.probation_txt h6{
    color: #283891;
    margin-bottom: 15px;
}
.probation_txt ul {
    padding-left: 15px;
}
.probation_txt ul li {
    font-size: 17px;
    line-height: 28px;
    color: #252525;
    margin-bottom: 15px;
    list-style: decimal;
    padding-left: 5px;
}
.probation_txt ul li::marker {
    font-weight: 700;
    color: #283891;
}
.household_group{
	width: 100%;
	margin-top: 40px;
	margin-bottom: 40px;
}
.termination_form h6{
	color: #283891;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 10px;
}
.household_radio label{
	color: #707070;
	font-size: 13px;
	font-weight: 300;
	line-height: 31px;
}
.radio-list {
    grid-gap: 30px;
    gap: 30px;
    padding-left: 17px;
}
.radio-list .wpcf7-radio{
	display: flex;
	grid-gap: 30px;
    gap: 30px;
}
.customRadio {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    color: #707070;
    font-size: 13px;
    font-weight: 300;
    line-height: 31px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.customRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.customRadio .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 50%;
	
}
.customRadio .checkmark:after {
	content: "";
	position: absolute;
	display: none;
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #707070;
}
.customRadio input:checked ~ .checkmark:after {
    display: block;
}
.wpcf7-radio .wpcf7-list-item {
    position: relative;
    padding-left: 20px;
    padding-right: 0px;
	margin: 0;
	line-height: 22px;
}
input[type="radio"] {
    height: 20px;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
}
input[type="radio"] + span::before {
    border: 1px solid #f00;
    border-radius: 20px;
    content: "";
    height: 18px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 18px;
}
input[type="radio"] + span::before {
    border-radius: 20px;
    content: "";
    left: 0;
    position: absolute;
    top: 3px;
	height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #707070;
}
input[type="radio"] + span::after {
	content: "";
    position: absolute;
    top: 8px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #707070;
	opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

input[type="radio"]:checked + span::after {
    opacity: 1;
}
.termination_sec .adult_box span{
	margin: 0;
}
.wpcf7-list-item-label{
    color: #707070;
    font-size: 13px !important;
    font-weight: 300;
    line-height: 22px !important;
	padding-left: 10px;
}
.household_input{
	padding-left: 32px;
    width: calc(100% - 304px);
}
.request_formsec .household_input .form-group{
	width: 100%;
}
.household_input input{
	width: 100%;
	text-align: center;
}
.houseeducation_group{
	width: 100%;
	margin-bottom: 40px;
}
.houseeducation_input{
	padding-left: 60px;
    width: calc(100% - 379px);
	display: flex;
	flex-wrap: wrap;
}
.request_formsec .houseeducation_input .form-group{
	width: 49.7%;
	margin-bottom: 10px;
}
.request_formsec .houseeducation_input .form-group input, .request_formsec .houseeducation_input1 .form-group input, .request_formsec .probation_input .form-group input	{
	text-align: center;
}
.request_formsec .houseeducation_input .form-group:nth-child(2){
	margin-right: 0;
}
.request_formsec .houseeducation_input1{
	margin-bottom: 14px;
}
.request_formsec .houseeducation_input1 .form-group{
	width: 100%;
}
.household_radio1{
	padding-left: 54px;
}
.probation_group {
	width: 100%;
}
.request_formsec .probation_input{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	    margin-bottom: 12px;
}
.request_formsec .probation_input .form-group{
	width: 49.8%;
	margin-bottom: 10px;
}
.request_formsec .probation_input .form-group:nth-child(2), .request_formsec .probation_input .form-group:nth-child(4){
	margin-right: 0;
}
.request_formsec .probation_input .form-group:nth-child(3){
	margin-right: 5px;
}
.radiobtn_main{
	width: 100%;
}
.radiobtn_main .household_radio{
	max-width: 698px;
}
.radiobtn_main .household_radio > label, .radiobtn_main .household_radio > p {
    width: calc(100% - 147px);
}
.request_formsec .radiobtn_main .houseeducation_input1{
    max-width: 698px;
	margin-top: 6px;
    margin-bottom: 10px;
}
.request_formsec .radiobtn_main .houseeducation_input1 input{
    text-align: left;
}
.termination_form input[type=submit]{
	margin-top: 12px;	
}
.termination_form form{
    justify-content: left;
}
.tab_main > iframe{
	width: 100%;
	height: 100%;
	min-height: 100vh;
}
div.dataTables_wrapper div.dataTables_info{
	display: none;
}
.main_tab .resp-tab-content{
	padding: 0;
}
.click_tabingmain p{
	margin-bottom: 25px;
}
.site-main{
	padding: 60px 0px;
}
.page-content ul{
	padding-left: 20px;
	margin-bottom: 15px;
}
.page-content ul li {
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 6px;
}
.page-content ul li a{
    color: #000000;
}
.site-main .page-header{
	margin-bottom: 25px;
}
.page-content p{
	margin-bottom: 10px;
}

.page-content .search-form{
	display: flex !important;
    position: relative;
    top: 0;
    right: 0;
	background: transparent;
    padding: 0;
	width: auto;
    box-shadow: none;
    border: 0;
	margin: 15px 0px;
	height: auto;
}
.page-content .search-form label{
	margin-right: 10px;
}
.page-content .search-form input[type=search] {
    width: auto;
    padding: 10px 20px;
    margin-left: 0;
    border: 1px solid #1F8DE3;
    outline: none;
    height: auto;
}
.page-content .search-form input[type=submit] {
    width: auto;
    padding: 5px 25px;
    background: #1F8DE3;
    margin: 0;
	position: relative;
	right: 0;
	top: 0;
}
.page-content h2{
	margin-bottom: 10px;
}
.entry-header h2 a {
    color: #000000;
    font-size: 22px;
    line-height: 28px;
	    margin-bottom: 10px;
    display: block;
}
.widget_archive select{
    padding: 12px 18px;
    background-color: #ffffff;
	font-size: 13px;
	color: #707070;
	font-weight: 300;
	border: 1px solid #E4E4E4;
}
.post-thumbnail{
	margin: 10px 0px;
	display: block;
}
.site-main article{
	margin-bottom: 35px;
}
.site-main h3{
	margin-bottom: 10px;
}
.comment-form-cookies-consent input{
	width: auto;
    vertical-align: top;
    margin-top: 6px;
    margin-right: 5px;
}
.comment-form-cookies-consent label{
	width: 91%;
    vertical-align: top;
}
.comment-form p{
	margin-bottom: 10px;
}
.comment-form input[type=submit]{
	width: auto;
}
.comment-respond {
	margin: 10px 0;
}
iframe .logged-in .wpadminbar{
	display: none;
}
.wpcf7-not-valid-tip {
    font-size: 12px !important;
	line-height: 21px;
}
.adult_box .mission_txt {
    margin-bottom: 35px;
}
#sub-accordionExample{
	margin-top: 20px;
}
.accordion-body ul.list-check{
	padding: 0;
	margin-bottom: 33px;
}
.accordion-body ul.list-check li{
	display: block;
	font-size: $px17;
	line-height: 28px;
	color: #000000;
	margin-bottom: 10px;
	position: relative;
	padding-left: 22px;
}
.accordion-body ul.list-check li:before{
	content:'';
	position: absolute;
	left: 0;
	top: -4px;
	width: 17px;
	height: 31px;
	background: url('../images/check.svg') no-repeat;
	background-size: cover;
}
.juvenlie_sec .mission_img{
	margin-bottom: 60px;
}
a.post-thumbnail img{
	width: 100%;
}
.cat-links a{
    color: #000000;
}
.search .site-main article.page {
    margin: 0;
    padding: 0;
}
.comments-link a{
	display: block;
}
.adult_box .request_formsec .form-group p span {
     margin-bottom: 0px;
}
.wpcf7 form .wpcf7-response-output {
    width: 100%;
}
[type=file] {
  padding: 8px 12px;
  border: 1px solid blue;
  border-radius: 4px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  overflow: hidden;
  color: red;
}
[type=file]::-webkit-file-upload-button, [type=file]::file-selector-button {
  position: absolute;
  left: -1000px;
  visibility: hidden;
  margin-left: 10px;
  height: 100%;
  width: 0px;
}
.request_formsec .houseeducation_input1 textarea {
    height: 57px;
	    resize: none;
}