@media (max-width: 1639px) {
.main_tab .container { max-width: 95%; }
.accordion-body {  padding: 10px 100px 19px 45px; }
.request_formsec .form-group:nth-child(7) { width: 12.84%; }
.request_formsec .houseeducation_input .form-group { width: 49.6%; }
.request_formsec .probation_input .form-group { width: 49.7%; }
}
@media (max-width: 1539px) {
.table thead tr th:nth-child(1){ width: 14% !important; }
.accordion-body {  padding: 10px 80px 19px 45px; }
.request_formsec .form-group.w-30,.request_formsec .form-group:nth-child(1), .request_formsec .form-group:nth-child(2), .request_formsec .form-group:nth-child(3), 
.request_formsec .form-group:nth-child(4), .request_formsec .form-group:nth-child(5), .request_formsec .form-group:nth-child(6) { width: 33.04%; }
.request_formsec .form-group:nth-child(7) {  width: 12.1%; }
.request_formsec .form-group:nth-child(9) { width: 11.56%; }
.request_formsec .form-group:nth-child(10), .request_formsec .form-group:nth-child(10) { width: 49.6%; }
.request_formsec .household_input .form-group, .request_formsec .houseeducation_input1 .form-group { width: 100%; }
.request_formsec .houseeducation_input .form-group { width: 49.6%; }
.request_formsec .probation_input .form-group {  width: 49.7%; }
}
@media (max-width: 1439px) {
.table thead tr th:nth-child(1) { width: 20% !important; }
.table thead tr th:nth-child(2) { width: 18% !important; }
.table thead tr th:nth-child(3) { width: 30% !important; }
.table thead tr th:nth-child(4) { width: 30% !important; }
.resp-tabs-list li {  font-size: 1.125rem;}
.request_formsec .form-group.w-30, .request_formsec .form-group:nth-child(1), .request_formsec .form-group:nth-child(2), .request_formsec .form-group:nth-child(3), 
.request_formsec .form-group:nth-child(4), .request_formsec .form-group:nth-child(5), .request_formsec .form-group:nth-child(6) { width: 33.02%; }
.request_formsec .form-group:nth-child(7) { width: 11.5%; }
.request_formsec .form-group:nth-child(9) {  width: 11.5%; }

.request_formsec .household_input .form-group, .request_formsec .houseeducation_input1 .form-group { width: 100%; }
.request_formsec .houseeducation_input .form-group { width: 49.6%; }
.request_formsec .probation_input .form-group {  width: 49.7%; }
}
@media (max-width: 1365px) {
.hearing_tabright { width: calc(100% - 380px);  padding-left: 25px; }
.click_tabright {  width: calc(100% - 380px);  padding-left: 60px; }
.hearing_tableft { width: 50%; }
.hearing_tabright { width: 50%; padding-left: 10px; }
.click_tableft {  width: 50%; }
.click_tabright { width: 50%;  padding-left: 20px; }
.carrer_contact .form-group.w-30 { width: 33.05%; }
.social_box { padding-left: 0; }
.accordion-body {  padding: 10px 45px 19px; }
.accountability_sec .accordion-body .accordion-main { padding-right: 0; }
.carrer_contact .form-group.w-30, .carrer_contact .form-group:nth-child(2), .carrer_contact .form-group:nth-child(3), .carrer_contact .form-group:nth-child(4) { width: 33.04%; }
.request_formsec .form-group.w-30, .request_formsec .form-group:nth-child(1), .request_formsec .form-group:nth-child(2), .request_formsec .form-group:nth-child(3), 
.request_formsec .form-group:nth-child(4), .request_formsec .form-group:nth-child(5), .request_formsec .form-group:nth-child(6) { width: 32.99%; }
.request_formsec .form-group:nth-child(7) {  width: 10.7%; }

.request_formsec .household_input .form-group, .request_formsec .houseeducation_input1 .form-group { width: 100%; }
.request_formsec .houseeducation_input .form-group { width: 49.5%; }
.request_formsec .probation_input .form-group {  width: 49.7%; }
.household_radio1 { padding-left: 0; width: 100%; }
}
@media (max-width: 1279px) {
.header .header_btm .main_menu nav ul li { padding: 0px 12px; }
.tab_sec { padding: 77px 40px 0px; }
.header .header_btm .main_menu nav ul li a { font-size: $px17; }
.table thead tr th, .table tr td { padding: 19px 25px; }
.system_box:before { width: 40px;  left: -40px; }
.system_box:after { width: 40px;  right: -40px; }
.contact_txt, .carrer_txt, .mission_txt, .history_txt, .probation_txt {  padding-left: 30px; }
.contact_form { padding-left: 0; }
.bg_sec { padding: 75px 40px 0px; }
.carrer_sec { padding: 64px 40px 64px; }	
.carrer_img, .mission_img {  margin-left: 30px; }
.carrer_contact .form-group.w-30 { width: 33.04%; }
.event_mainbox { padding: 64px 40px 0px; }	
.press_event { padding: 35px 40px 0px; }	
.client_sec {  margin-left: 30px; }
.event_mainbox.social_sec{ padding: 0px 40px 0px; }	
.ourpeople_tab { padding: 36px 40px 0px; }	
.carrer_contact .form-group.w-30, .carrer_contact .form-group:nth-child(2), .carrer_contact .form-group:nth-child(3), .carrer_contact .form-group:nth-child(4) { width: 33%; }
.request_formsec .form-group.w-30, .request_formsec .form-group:nth-child(1), .request_formsec .form-group:nth-child(2), .request_formsec .form-group:nth-child(3), 
.request_formsec .form-group:nth-child(4), .request_formsec .form-group:nth-child(5), .request_formsec .form-group:nth-child(6) {  width: 32.9%; }
.request_formsec .form-group:nth-child(7) { width: 10.1%; }

.request_formsec .household_input .form-group, .request_formsec .houseeducation_input1 .form-group { width: 100%; }
.request_formsec .houseeducation_input .form-group { width: 49.5%; }
.request_formsec .probation_input .form-group {  width: 49.7%; }
}
@media (max-width: 1179px) {
.header .header_btm .main_menu nav ul li a { font-size: 1rem; }
.header .header_btm .main_menu nav ul li { padding: 0px 10px; }
.tab_top , .system_tab, .hearing_tab, .tab_main div > .tab_main.click_tab, .tab_main div > .tab_main.hearing_tab{  padding: 42px 35px 15px; }
.system_tab .hearing_tabtop {  padding: 0px; }
.video_txt {  padding: 55px 0px; }
.btn.btn-primary {  font-size: 1.0625rem; } 
.carrer_contact .form-group.w-30 {  width: 33.01%; }
.event_box:nth-child(3n+2) { margin: 0px 35px 45px; }
.event_txt h5, .press_event .event_txt h5 { padding-right: 0; }
.event_txt { padding: 0; }
.event_txt a.event-arrow { margin-left: 0; }
h1, .main_title h1, .title h1, .carrer_txt h1, .mission_title h1, .expungement_main h1  { font-size: 34px; line-height: normal;  }
.frequent_tab ul li { width: 50%; }
.frequent_tab ul li:nth-child(n+1):nth-child(-n+3), .frequent_tab ul li:nth-child(n+7):nth-child(-n+9), .frequent_tab ul li:nth-child(n+13):nth-child(-n+15), 
.frequent_tab ul li:nth-child(n+19):nth-child(-n+21) { background-color: #fff; }
.frequent_tab ul li:nth-child(n+1):nth-child(-n+2), .frequent_tab ul li:nth-child(n+5):nth-child(-n+6), .frequent_tab ul li:nth-child(n+9):nth-child(-n+10), 
.frequent_tab ul li:nth-child(n+13):nth-child(-n+14), .frequent_tab ul li:nth-child(n+17):nth-child(-n+18)
, .frequent_tab ul li:nth-child(n+23):nth-child(-n+24) {     background-color: #F8F8F8; }
.carrer_contact .form-group.w-30, .carrer_contact .form-group:nth-child(2), .carrer_contact .form-group:nth-child(3), .carrer_contact .form-group:nth-child(4) { width: 32.9%; }
.request_formsec .form-group:nth-child(7) { width: 9.6%; }

.request_formsec .form-group:nth-child(9) { width: 10.9%; }
.request_formsec .household_input .form-group, .request_formsec .houseeducation_input1 .form-group { width: 100%; }
.request_formsec .houseeducation_input .form-group { width: 49.4%; }
.request_formsec .probation_input .form-group {  width: 49.7%; }

}
@media (max-width: 1079px) {
.main_tab {  padding: 60px 0px; }
.tab_sec , .bg_sec{ padding: 45px 30px 0px; }
.system_box:before { width: 30px;  left: -30px; }
.system_box:after { width: 30px;  right: -30px; }
.tab_main p { font-size: 1.125rem; }
.header .header_btm .logo { width: 210px; }
.header .header_btm .main_menu { width: calc(100% - 210px); }
.header .header_btm .main_menu nav ul li { padding: 0px 6px; }
.togglesearch {  right: 11%; }
.carrer_sec { padding: 45px 40px; }
.event_mainbox { padding: 45px 40px 0px; }	
.carrer_contact .form-group.w-30 { width: 32.9%; }
.event_mainbox.social_sec{ padding: 0px 40px 0px; }	
.social_list ul {  columns: 2; }
.socialvideo_box a.video_btn { width: 90px; height: 90px; }
h1, .main_title h1, .title h1, .carrer_txt h1, .mission_title h1, .expungement_main h1  { font-size: 32px;  }
.ourpeople_tab { padding: 36px 30px 0px; }	

.request_formsec .form-group.w-30, .request_formsec .form-group:nth-child(1), .request_formsec .form-group:nth-child(2), .request_formsec .form-group:nth-child(3),
.request_formsec .form-group:nth-child(4), .request_formsec .form-group:nth-child(5), .request_formsec .form-group:nth-child(6) {  width: 49.6%; }
.request_formsec .form-group:nth-child(3), .request_formsec .form-group:nth-child(5) { margin-right: 0; }
.request_formsec .form-group:nth-child(4) { margin-right: 5px; }
.request_formsec .form-group:nth-child(7) { width: 18.3%; }
.request_formsec .form-group:nth-child(9) {  width: 18%; }
.request_formsec .form-group:nth-child(10), .request_formsec .form-group:nth-child(11) { width: 49.6%; }
.request_formsec .household_input .form-group, .request_formsec .houseeducation_input1 .form-group { width: 100%; }
.request_formsec .houseeducation_input .form-group { width: 49.4%; }
.request_formsec .probation_input .form-group {  width: 49.6%; }
.houseeducation_input { padding-left: 0; width: 100%; }
}
@media (max-width: 991px) {
body.open { overflow: hidden; }
body.open #wrapper:before{ opacity: 1; visibility: visible; z-index: 9; }
.nav-icon { display: block; z-index: 99; } 
.header .header_main .navbar { display: none; position: fixed;  top: 157px; left: 0; width: 100%; right: 0; float: none; z-index: 99; background: #112048;
 transition: all 0.3s ease-in-out;-webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out;
 -o-transition: all 0.3s ease-in-out; padding: 30px 0px; overflow: auto; max-height: calc(100vh - 157px); max-height: -moz-calc(100vh - 157px);  }
.open .header .header_main .navbar { display: block;     padding: 0; }
.header .header_main .main_menu nav ul { display: block; }
.header .header_main .main_menu nav ul li ul.sub-menu { display: none; }
.header .header_main .navbar ul { width: 100%;  padding: 30px 20px;  display: block; text-align: center; }
.header .header_main .navbar ul li { display: block; margin: 0;  padding-bottom: 25px; }
.header .header_main .navbar ul li.btn {  display: inline-block; }
.header .header_main .main_menu nav ul li { display: block;   margin-bottom: 12px; }
.header .header_main .main_menu nav ul li:last-child { margin-bottom: 0px; }
.header .header_btm .main_menu nav ul li a { color: #ffffff; }
.header .header_btm .main_menu { display: none; }
.open .header .header_btm .main_menu { display: block; }
main {  margin-top: -15px; }
.tab_top, .system_tab, .hearing_tab, .tab_main div > .tab_main.click_tab, .tab_main div > .tab_main.hearing_tab { padding: 42px 20px 15px; }
.tab_sec { padding: 35px 20px 0px; }
.table-responsive { overflow-x: auto; display: block;  width: 100%; }
.system_box h3 { font-size: 24px; }
.video_txt { flex-wrap: wrap; }
.video_leftxt { width: 100%;  padding-right: 0; margin-bottom: 20px; }
.video_leftxt p span { font-size: 18px; line-height: 28px; }	
.video_rigtxt, .video_box.fullbox .video_boxleft { width: 100%; height: 300px; padding-left: 0; margin: 0 auto; }
.video_mainbox { padding: 25px 25px; }
.video_box h5 { font-size: 20px; }
.container {  padding: 0 20px; }
.footer .container {  padding: 0px 20px; }
#example_wrapper .dt-row .col-sm-12 { overflow-x: auto; display: block; width: 100%; }
.table thead tr th, .table tr td { padding: 19px 16px; }
.table thead th:after { right: 8px !important; }

.sub-menu { transition: none; opacity: 1; transform: none; pointer-events: all; position: static; box-shadow: none; border-radius: 0; padding: 0; display: none; visibility: visible; }
.click_menu { width: 10px; height: 10px; position: absolute; right: 5px; cursor: pointer; top: 5px;  border-style: solid; transform: rotate(45deg); border-width: 0px 2px 2px 0;
 padding: 3px; background-position: right;  z-index: 999; border-color: #ffffff;  display: block; }
.click_menu.open2 { transform: rotate(-135deg); background-position: left; }
.header .header_btm .main_menu nav ul li ul.sub-menu li {  padding: 5px 0px; }
.system_box:before { width: 40px;  left: -40px; }
.system_box:after { width: 40px;  right: -40px; }
.contact_txt, .carrer_txt, .mission_txt, .history_txt, .expungement_main , .probation_txt { padding-left: 0; }
h1, .main_title h1,  .title h1, .carrer_txt h1, .mission_title h1, .expungement_main h1 { font-size: 2rem;  line-height: 49px; }
.carrer_img, .mission_img {  margin-left: 0px; }
.carrer_contact .form-group.w-30 { width: 32.8%; }
.event-col { padding: 0 15px; }
.client_sec { margin-left: 0px; }
.expungement_img img { padding-left: 0; }
.resp-tabs-list li { width: 37%; }
.frequent_tab .team_txt h6 { font-size: 16px; }
.ourpeople_tab { padding: 36px 20px 0px; }	
.carrer_contact .form-group.w-30, .carrer_contact .form-group:nth-child(2), .carrer_contact .form-group:nth-child(3), .carrer_contact .form-group:nth-child(4) { width: 32.8%; }
.request_formsec .form-group.w-30, .request_formsec .form-group:nth-child(1), .request_formsec .form-group:nth-child(2), .request_formsec .form-group:nth-child(3), 
.request_formsec .form-group:nth-child(4), .request_formsec .form-group:nth-child(5) , .request_formsec .form-group:nth-child(6){ width: 49.5%; }
.request_formsec .form-group:nth-child(7) { width: 14.2%; }
.request_formsec .form-group:nth-child(10), .request_formsec .form-group:nth-child(11) { width: 49.5%; }
.request_formsec .form-group:nth-child(8) { width: 15.6%; }
.request_formsec .household_input .form-group, .request_formsec .houseeducation_input1 .form-group { width: 100%; }
.request_formsec .houseeducation_input .form-group { width: 49.4%; }
.request_formsec .probation_input .form-group {  width: 49.5%; }
.household_input {  padding-left: 15px; }
}
@media (max-width: 767px) {
h2.resp-accordion, .resp-accordion.h2 { font-size: 1rem;  padding: 10px 15px; font-weight: 700; color: #283891;  background-color: #F6F6F6; margin-right: 0px; 
 border: 1px solid #c1c1c1 !important; }
.tab_main p {  font-size: 1rem; line-height: 28px; }
.follow_social {  max-width: 300px; margin: 0 auto; margin-bottom: 30px;}
.footer .footer_top .footer_icon {  padding: 0 0 15px; }
.footer .footer_top h5 { margin: 0; }
.pandamic_box h4{ font-size: 1.25rem; }
h2.resp-tab-active, .resp-tab-active.h2 { background-color: #ffffff !important; box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16); color: #FF5C11 !important; }
.tab_top {  margin-bottom: 40px; }
.tab_sec, .bg_sec , .press_event , .event_mainbox{  padding: 20px 20px 0; }
.event_mainbox.social_sec{ padding: 0px 20px 0px; }	
.carrer_sec { padding: 20px 20px; }
.tab_main { padding: 0px 0px 15px; }
.main_title {  margin-bottom: 25px; }
h1, .main_title h1, .title h1, .carrer_txt h1 , .mission_title h1, .expungement_main h1 { font-size: $px30; line-height: 42px;}
.hearing_tableft { width: 100%;  margin-bottom: 20px; }
.hearing_tabright { width: 50%; padding-left: 0px; }
.video_box { flex-wrap: wrap; }
.video_boxleft, .click_tableft { width: 100%; margin-right: 0;     margin-bottom: 30px; }
.video_boxright { width: 100%; }
.hearing_tabright { width: 100%; }
.video_box iframe {  height: 250px; }
.click_tabright { width: 100%; padding-left: 0px; }
.hearing_tabtxt ul li , .hearing_list ul li{  font-size: 1rem; }
.main_tab { padding: 40px 0px; }
.follow_us .titles { font-size: 1.5rem; padding-bottom: 30px; }
.header .header_btm .logo { width: 150px;  position: relative;  top: 0; }
main {  margin-top: 0; }
.header_btm { padding: 4px 0 0; }
.header .header_main .navbar { top: 143px; }
.container { padding: 0 15px; }
.tab_main p a, .hearing_list ul li a {
display: inline-block; word-break: break-all; }
.video_leftxt p span { font-size: 16px; }
.resp-arrow { border-top: 12px solid #283891; }
h2.resp-tab-active span.resp-arrow, .resp-tab-active.h2 span.resp-arrow{ border-bottom: 12px solid #283891; }
ul.resp-tabs-list { display: block; border-bottom: 0; float: left; margin-bottom: 30px; }
.resp-tabs-list li {  width: 100%; margin: 0;  }
.system_box:before { width: 20px;  left: -20px; }
.system_box:after { width: 20px;  right: -20px; }
.main_tab .container {  max-width: 100%; }
ul.resp-tabs-list { padding: 0; }
.contact_txt p, .contact_txt h6, .contact_txt a , .carrer_txt span, .mission_sec .mission_title p, .social_box span, .expungement_main span{ font-size: 18px;  line-height: 30px; }
.contact_form {  max-width: 100%; }
.contact_txt, .contact_form, .title, .carrer_txt {  margin-bottom: 30px; }
.carrer_contact .form-group.w-30 { width: 100%;  margin-right: 0; }
h2, .carrer_contact .contact_form h2 , .request_formtitle h2, .page-content h2 {   font-size: 26px; margin-bottom: 20px; }
.event_txt p { padding-bottom: 20px; min-height: auto; }
.event_box {  margin-bottom: 30px; }
.mission_sec .col-7 , .mission_sec .col-5, .adult_box .col-7, .adult_box .col-5  { width: 100%;  }
.social_txt, .social_box h6 {  margin-bottom: 30px; }
.connect_box ul li {  width: 100%; padding: 0; margin: 0;}
.connect_box ul li:before { display: none; }
.socialvideo_box { margin-bottom: 30px; }
.expungement_txt { margin-bottom: 30px; }
.expungement_main h6, .expungement_main ul, .expungement_main ul p{  max-width: 100%; }
.expungement_main ul li { margin-bottom: 10px; }
.meet_tab ul.resp-tabs-list, .ourpeople_tab ul.resp-tabs-list { display: none}
.tab_sec.meet_tab , .ourpeople_tab { padding: 20px 20px; }
.meet_tab .resp-tab-content, .ourpeople_tab .resp-tab-content { padding: 25px 0; }
.meet_tab h2.resp-accordion, .resp-accordion.h2, .ourpeople_tab h2.resp-accordion, .resp-accordion.h2 { border: 1px solid #c1c1c1 !important;  margin-bottom: 10px; }
.frequent_tab .team_txt p { font-size: 16px; }
.adult_box h1 { max-width: 100%; }
.adult_box span {  font-size: 18px; line-height: 28px; }
.accordion-button { padding: 15px 65px 15px 25px;  font-size: 18px; }
.accordion-body { padding: 10px 25px 19px; }
.accordion-body ul {  padding-left: 20px; }
.account_imgbox {  margin-right: 25px; }
.carrer_contact .form-group.w-30, .carrer_contact .form-group:nth-child(2), .carrer_contact .form-group:nth-child(3), .carrer_contact .form-group:nth-child(4) {
 width: 100%;  margin-right: 0;
}
.request_formsec .form-group.w-30, .request_formsec .form-group:nth-child(1), .request_formsec .form-group:nth-child(2), .request_formsec .form-group:nth-child(3), 
.request_formsec .form-group:nth-child(4), .request_formsec .form-group:nth-child(5), .request_formsec .form-group:nth-child(5) { width: 100%;  margin-right: 0; }
.request_formsec .form-group:nth-child(7), .request_formsec .form-group:nth-child(8), .request_formsec .form-group:nth-child(9) { width: 32.7%; }
.request_formsec .form-group:nth-child(10), .request_formsec .form-group:nth-child(11) , .request_formsec .form-group:nth-child(6){ width: 100%; margin-right: 0; }
.request_formsec .probation_input .form-group:nth-child(3) {  margin-right: 0px; }
.home h2.resp-accordion { display: none; }
.home .resp-tabs-list li {  padding: 10px 15px; }
html {
    margin-top: 0px !important;
}
}
@media (max-width: 639px) {
.frequent_tab ul li { width: 100%; }
.frequent_tab ul li:nth-child(n+1):nth-child(-n+2), .frequent_tab ul li:nth-child(n+5):nth-child(-n+6), .frequent_tab ul li:nth-child(n+9):nth-child(-n+10), 
.frequent_tab ul li:nth-child(n+13):nth-child(-n+14), .frequent_tab ul li:nth-child(n+17):nth-child(-n+18), .frequent_tab ul li:nth-child(n+23):nth-child(-n+24) {
 background-color: #ffffff; 
}
.frequent_tab ul li {  padding: 15px 15px; }
.frequent_tab ul li:nth-child(odd){ background-color: #F8F8F8 !important; }
.account_imgbox { margin-right: 0px; width: 100%; margin-bottom: 20px; }
.request_formsec .form-group:nth-child(7), .request_formsec .form-group:nth-child(8), .request_formsec .form-group:nth-child(9) { width: 32.5%; }

.household_radio label, .radiobtn_main .household_radio > label {  width: 100%; display: block; }
.radio-list {  padding-left: 0px; width: 100%; }
.household_input { width: 100%;  padding-left: 0; }
.household_group { margin-top: 20px; margin-bottom: 20px; }
.houseeducation_group { margin-bottom: 20px; }
.household_radio  { margin-bottom: 5px; }

}
@media (max-width: 575px) {
.header .header_top ul li {  margin-right: 10px; }
.header .header_top ul li a.language { padding-left: 10px; margin-left: 3px; }
.header .header_top ul li a i.fa-magnifying-glass { font-size: 1.125rem; }
h1, .main_title h1, .title h1, .carrer_txt h1, .mission_title h1, .expungement_main h1  { font-size: 1.5rem; line-height: 36px; }
.footer .footer_top h5 { font-size: 1.125rem; line-height: 24px; }
.nav-icon { top: 85px; }
.tab_top, .system_tab, .hearing_tab, .tab_main div > .tab_main.click_tab , .tab_main div > .tab_main.hearing_tab{ padding: 0px; }
.video_mainbox {  padding: 25px 15px; }
.header .header_main .navbar { top: 138px; }
.accordion-item {  margin-bottom: 20px; }
.accordion-button {  padding: 15px 52px 15px 20px; }
.accordion-body {  padding: 10px 20px 19px; }
.accordion-button::after { width: 36px !important;  height: 32px !important; background-size: contain !important; }
.accordion-button:not(.collapsed)::after { background-size: contain !important; }
.accountability_sec .accordion-body { padding: 10px 20px 19px 20px; }
.request_formsec .form-group:nth-child(7), .request_formsec .form-group:nth-child(8), .request_formsec .form-group:nth-child(9) { width: 32.3%; }
}
@media (max-width: 479px) {
.header_top ul { flex-wrap: wrap; justify-content: center !important; max-width: 230px;  margin: 0 auto; }
.nav-icon { top: 102px; }
.btn.btn-primary, .pandamic_box p , .expungement_main ul li{ font-size: 16px; }
.header .header_main .navbar { top: 156px; }
.togglesearch { right: 10px; top: 53px; }
.togglesearch:before { right: 50%; }
.resp-tabs-list li { font-size: 1.0625rem; }
.system_box {  padding: 30px 15px; }
.video_txt {  padding: 55px 0px 35px; }
.carrer_txt span , .social_box span, .expungement_main span, .adult_box span, .accordion-body p span, .mission_txt p span { font-size: 17px; line-height: 28px; }
.contact_txt p, .contact_txt h6, .carrer_txt p, .carrer_img a, .mission_sec .mission_title p, .accordion-body p, .accordion-body ul li, .accordion-body h6, 
.accountability_sec .accordion-body p, .connect_box p, .connect_box ul li, .termination_sec .mission_title ul li, .probation_txt ul li , .page-content ul li,
.tab_main .tabcenter_txt p , .accordion-body ul.list-check li, .hearing_tabtxt ul.list-check li{ font-size: 16px; line-height: 28px; }
.contact_map iframe {  height: 250px; }
.contact_form input[type=submit] { height: 42px;  font-size: 16px; line-height: 26px; }
.event_box { padding: 15px 15px; }
.event_img { margin-bottom: 20px; }
.event_txt h5 { font-size: 18px; }
.event_txt p { font-size: 15px; line-height: 24px; }
.tab_sec, .bg_sec, .press_event, .event_mainbox { padding: 20px 15px 0; }
.event_mainbox.social_sec{ padding: 0px 15px 0px; }	
.contact_txt, .contact_form, .title, .carrer_txt {  margin-bottom: 20px; }
.event_txt a.event-arrow { width: 45px; height: 40px; }
.event_txt a.event-arrow img { width: 10px; }
.press_title h6 { font-size: 16px; line-height: 26px; }
.mission_sec h5 , .social_box h5, .social_box h6{ font-size: 18px; }
.history_txt { margin-bottom: 30px; }
.client-slider { padding: 30px 25px 60px; }
.connect_box h4 { font-size: 20px; }
.socialvideo_box a.video_btn { width: 50px;  height: 50px; }
.social_list ul { -moz-columns: 1;  columns: 1; }
.accordion-body ul { padding-left: 20px; }
.accordion-button { padding: 15px 50px 15px 20px;  font-size: 16px; }
 .request_formsec .form-group:nth-child(6), .request_formsec .form-group:nth-child(7), .request_formsec .form-group:nth-child(8), .request_formsec .form-group:nth-child(9) { width: 100%; margin-right: 0; }
.request_formsec { padding: 20px 15px; }
.contact_form input[type=submit], input[type=submit] { padding: 8px 34px; font-size: 16px;  line-height: 26px; }
.page-content .search-form {  display: block !important; }
.page-content .search-form label {  margin-bottom: 10px; }
h2, .h2, .carrer_contact .contact_form h2, .request_formtitle h2, .page-content h2 {
    font-size: 22px;
	}
.site-main .page-header {
    margin-bottom: 15px;
}
}
@media (max-width: 375px) {
.juvenlie_box p { font-size: 14px; }
}